<template>
  <section class="hero is-small">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a
          @click="$router.push('/').catch((err) => {})"
          class="navbar-item logo"
        >
          <img src="@/assets/images/logo-long.png" width="100%" />
        </a>

        <a
          @click="showingMenu = !showingMenu"
          :class="{ 'is-active': showingMenu }"
          role="button"
          class="navbar-burger"
          aria-label="menu"
          :aria-expanded="showingMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div :class="{ 'is-active': showingMenu }" class="navbar-menu">
        <div class="navbar-start">
          <a @click="$router.push('/').catch((err) => {})" class="navbar-item"
            >Home</a
          >
          <!-- <a
            @click="$router.push('/maps').catch((err) => {})"
            class="navbar-item"
            >Maps</a
          > -->
          <a
            @click="$router.push('/shop').catch((err) => {})"
            class="navbar-item"
            >Shop</a
          >
          <a
            href="https://magistersmanual.buzzsprout.com/"
            target="_blank"
            class="navbar-item"
            >Podcast</a
          >
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
  // - components
  // - mixins
  // - props
  // - data
  // - computed
  // - watch
  // - lifecycle
  // - methods
  // - template

  export default {
    data() {
      return {
        showingMenu: false,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .navbar-burger {
    color: white;
  }

  .navbar-menu {
    background-color: inherit;
  }

  .logo {
    width: 200px;

    img {
      max-height: inherit !important;
    }
  }
</style>
